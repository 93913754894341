import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'
export default {
    install(Vue) {
        const connection = new HubConnectionBuilder()
            .withUrl('https://api.advogadoconecta.com.br/messageshub')
            //.withUrl('https://localhost:44340/messageshub')
            .configureLogging(LogLevel.None)
            .build()

        const messageHub = new Vue() 
        Vue.prototype.$messageHub = messageHub

        let startedPromise = null
        function start() {
            startedPromise = connection.start().catch(err => {
                console.error('Falha ao conectar no servidor de mensageria', err)
                return new Promise((resolve, reject) =>
                    setTimeout(() => start().then(resolve).catch(reject), 5000))
            })
            return startedPromise
        }

        connection.onclose(() => start())

        connection.on('NewMessage', (message) => 
            messageHub.$emit('new-message', message))

        connection.on('ClientMessageReceivedConfirmation', (message) => 
            messageHub.$emit('sent-message-confirmation', message))
            
        messageHub.SendMessage = (message) => {
            return startedPromise
                .then(() => connection.invoke('ClientsToMessage', message))
                .catch(console.error)
        }
            
        messageHub.StartedMessageCanva = (chatId) => {
            return startedPromise
                .then(() => connection.invoke('ClientStartedMessageCanva', chatId))
                .catch(console.error)
        } 

        messageHub.LeaveMessageCanva = () => {
            return startedPromise
                .then(() => connection.invoke('ClientLeaveMessageCanva'))
                .catch(console.error)
        }

        start()
    }
}