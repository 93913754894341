export default {
    namespaced: true,
    state: {
        LastId: 0,
        ChatInfo: {
            UserId: '',
            ConversationId: '',
            HeaderName: 'Advogado Conecta',
        },
        SentMessageAwaitingConfirmation: false,
        SentMessageWaitingResponse: false,
        Messages: []
    },
    getters: {
    },
    mutations: {
        SetWaiting(state, setTo){
            state.WaitingResponse = setTo;
        },
        AddMessage(state, message) {
            message.id = `msg-${state.LastId}`
            state.Messages.push(message);
            state.LastId++;
        },
        ClearMessages(state) {
            state.Messages = []
            state.LastId = 0
        },
        SentMessageAwait(state, status) {
            state.SentMessageAwaitingConfirmation = status;
        },
        SentMessageWaitingResponse(state, status) {
            state.SentMessageWaitingResponse = status;
        },
        SetConversationId(state, ConversationId) {
            state.ChatInfo.ConversationId = ConversationId;
        },
        SetUserId(state, userId) {
            state.ChatInfo.UserId = userId;
        }
    },
    actions: {
        SetUserId({ commit }, userId) {
            commit("SetUserId", userId)
        },
        SetConversationId({ commit }, ConversationId) {
            commit("SetConversationId", ConversationId)
        },
        SentMessageWaitingResponse({ commit }, status) {
            commit("SentMessageWaitingResponse", status)
        },
        SentMessageAwait({ commit }, status) {
            commit("SentMessageAwait", status)
        },
        SendMessage({ commit }, message) {
            commit("AddMessage", message)
        },
        StartNew({commit}){
            commit("ClearMessages")
        }
    }
}