<template>
    <div class="chat-log__item " :class="(Message.own ? 'chat-log__item--own' : '')">
        <h3 class="chat-log__author">{{ Message.from }}<small class="p-1">{{ GetHourFromMessage }}</small></h3>
        <div class="chat-log__message">{{ Message.message }}</div>
    </div>
</template>

<script>
export default {
    props: ["Message"],
	computed: {
		GetHourFromMessage() {
			var date = new Date(this.Message.timestamp);
			
			var hours = date.getHours();
			var minutes = date.getMinutes();
			var seconds = date.getSeconds();

			var formattedHours = ("0" + hours).slice(-2);
			var formattedMinutes = ("0" + minutes).slice(-2);
			var formattedSeconds = ("0" + seconds).slice(-2);

			var timeString = formattedHours + ':' + formattedMinutes;

			return timeString;
		}
	}
}
</script>

<style scoped>
.chat-log__author {
	margin: 0 auto .5em;
	font-size: 14px;
	font-weight: bold;
	text-align: left;
}
.chat-log__item {
	background: #fafafa;
	padding: 10px;
	margin: 0 auto 20px;
	max-width: 80%;
	float: left;
	border-radius: 4px;
	box-shadow: 0 1px 2px rgba(0,0,0,.1);
	clear: both;
}
.chat-log__message {
	text-align: justify;
	white-space: pre-line;
}

.chat-log__item.chat-log__item--own {
	float: right;
	background: #DCF8C6;
	text-align: right;
}
</style>