<template>
    <div class="container-fluid p-0">
        <ChatHeader />
        <div class="main">
            <ChatMessagesCanva />
            <ChatInput @SendMessage="SendMessage" @StartNew="StartNew" />
        </div>
    </div>
</template>
  
<script>
import ChatHeader from '@/components/ChatHeader.vue'
import ChatMessagesCanva from '@/components/ChatMessagesCanva.vue'
import ChatInput from '@/components/ChatInput.vue'

export default {
    name: 'ChatView',
    components: {
        ChatHeader, ChatMessagesCanva, ChatInput
    },
    created () {
        var messageUUID = this.GenerateUUID()
        var userUUID = this.GetUserUUID()
        this.$store.dispatch("Chat/SetUserId", userUUID)
        this.$store.dispatch("Chat/SetConversationId", messageUUID)

        this.$messageHub.$on('new-message', this.onMessageReceived)
        this.$messageHub.$on('sent-message-confirmation', this.onMessageSentConfirmation)
        this.$messageHub.StartedMessageCanva(userUUID)

        this.FirstMessage();
    },
    beforeDestroy () {
        this.$messageHub.$off('new-message', this.onMessageReceived)
        this.$messageHub.$off('sent-message-confirmation', this.onMessageSentConfirmation)
        this.$messageHub.LeaveMessageCanva()
    },
    methods: {
        GetUserUUID() {
            let uuid = localStorage.getItem('UserId');

            if (uuid == undefined || uuid == "") 
            {
                uuid = this.GenerateUUID()
                localStorage.setItem('UserId', uuid);
            }

            return uuid;
        },
        FirstMessage() {
            var date = new Date();
            this.$store.dispatch("Chat/SendMessage", {
                "own": false,
                "from": "Advogado Conecta",
                "message": "Olá, somos o Advogado Conecta e estamos aqui para tirar suas dúvidas jurídicas.",
                "timestamp": date.toISOString()
            })
        },
        StartNew(){
            if (confirm("O histórico será limpo e será iniciado um chat sem contexto.\r\n\r\nConfirma?")){
                var messageUUID = this.GenerateUUID()
                this.$store.dispatch("Chat/SetConversationId", messageUUID)
                this.$store.dispatch("Chat/StartNew")
                this.FirstMessage();
            }
        },
        SendMessage(message) {
            var date = new Date();
            var dateString = date.toISOString();

            var messageBuild = {
                UserId: this.$store.state.Chat.ChatInfo.UserId,
                ConversationId: this.$store.state.Chat.ChatInfo.ConversationId,
                Timestamp: dateString,
                Message: message
            }

            this.$messageHub.SendMessage(messageBuild)
            this.$store.dispatch("Chat/SentMessageAwait", true)
            this.$store.dispatch("Chat/SentMessageWaitingResponse", true)
        },
        onMessageSentConfirmation(message) {
            message.own = true;
            message.from = "Usuário";
            this.$store.dispatch("Chat/SendMessage", message)
            this.$store.dispatch("Chat/SentMessageAwait", false)
        },
        onMessageReceived(message) {
            message.own = false;
            message.from = "Advogado Conecta";
            this.$store.dispatch("Chat/SentMessageWaitingResponse", false)
            this.$store.dispatch("Chat/SendMessage", message)
        },
        GenerateUUID() {
            var d = new Date().getTime();
            if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
                d += performance.now(); //use high-precision timer if available
            }
            var newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            
            return newGuid;
        }
    }
}
</script>

<style scoped>
.main {
    height: 76vh;
    padding-top: 55px;
    overflow-x: auto;
}
</style>
  