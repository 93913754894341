<template>
    <div class="page-header">
        <h2>{{ HeaderName }}</h2>
    </div>
</template>

<script>
export default {
    computed: {
        HeaderName() {
            return this.$store.state.Chat.ChatInfo.HeaderName;
        }
    }
}
</script>
<style scoped>
.page-header {
    background-color: #006A4E;
    margin: 0;
    padding: 20px 0 10px;
    color: #FFFFFF;
    position: fixed;
    width: 100%;
    z-index: 1;
    text-align: center;
    height: 75px;
}
</style>