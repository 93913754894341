<template>
    <div>
        <div v-if="!isAuthenticated">
        <form @submit.prevent="checkPassword">
            <input type="password" v-model="password" placeholder="Digite a senha" />
            <button type="submit">Entrar</button>
        </form>
        </div>
        <div v-else>
            <ChatView/>
        </div>
    </div>
</template>  
<script>
import ChatView from '@/views/ChatView'

export default {
  data() {
    return {
      password: '',
      isAuthenticated: false,
    };
  },
  components: {
        ChatView
    },
  methods: {
    checkPassword() {
      const hardcodedPassword = 'Franccc123$$';

      if (this.password === hardcodedPassword) {
        this.isAuthenticated = true;
      } else {
        alert('Senha incorreta!');
        this.password = ''; // Limpa a senha digitada
      }
    },
  },
};
</script>
