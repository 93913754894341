<template>
    <div class="chat-form">
        <div class="container ">
            <form class="">
                <div class="row" v-if="FirstQuestion">
                    <div class="col-sm-12 col-xs-12 m-1">
                        <input type="text" class="form-control" v-model="Message" placeholder="Mensagem..." />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-5 col-xs-3 m-1" v-if="FirstQuestion">
                        <button type="submit" :class="(Waiting ? 'disabled' : '')" class="btn btn-success btn-block" @click.prevent="SendMessage">Enviar</button>
                    </div>
                    <div class="col-md-6 col-sm-5 col-xs-3 m-1" v-if="SecondQuestion">
                        <button type="submit" :class="(Waiting ? 'disabled' : '')" class="btn btn-warning btn-block" @click.prevent="SendMoreInfo">Quero mais detalhes</button>
                    </div>
                    <div class="col-md-6 col-sm-5 col-xs-3 m-1" v-if="SecondQuestion || LastQuestion">
                        <button type="submit" class="btn btn-success btn-block" @click.prevent="CallWhatsapp">Falar com um Advogado</button>
                    </div>
                    <div class="col-md-6 col-sm-5 col-xs-2 m-1">
                        <button type="submit" class="btn btn-info btn-block" @click.prevent="StartNew">Nova conversa</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-xs-12 m-1 text-center">
                        <span style="font-size: 12px;color: #8f8f8f;">
                            Advogado Conecta pode cometer erros. Considere verificar informações importantes. Desenvolvido pela <a href="https://www.instagram.com/bastosvidal.adv" target="_blank">Bastos Vidal Advocacia</a>.
                        </span>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
export default {
    data: function () {
        return {
            Message: "",
            OwnSet: true,
        }
    },
    computed:{
        Waiting() {
            return this.$store.state.Chat.SentMessageWaitingResponse;
        },
        FirstQuestion() {
            return this.$store.state.Chat.Messages.length < 3;
        },
        SecondQuestion() {
            return this.$store.state.Chat.Messages.length >= 3 && this.$store.state.Chat.Messages.length < 5;
        },
        LastQuestion() {
            return this.$store.state.Chat.Messages.length >= 5;
        }
    },
    methods: {
        CallWhatsapp() {
            //var msg = [];
            // for(var x=1;x<this.$store.state.Chat.Messages.length;x++){
            //     msg.push(this.$store.state.Chat.Messages[x].message);
            // }
            // var text = msg.join('\r\n',msg);
            
            var text = encodeURIComponent(`AdvConecta: ${this.$store.state.Chat.Messages[1].message}`);
            console.log(text);
            window.open(`https://wa.me/5547988581010?text=${text}`, '_blank');
        },
        ResetMsg(){
            this.Message = ''
        },
        StartNew() {
            this.$emit("StartNew")
        },
        SendMoreInfo(e) {
            this.$emit("SendMessage", "Traga mais detalhes sobre cada ponto comentado")
        },
        SendMessage(e) {
            this.$emit("SendMessage", this.Message)
            this.ResetMsg()
        }
    }
}
</script>
<style scoped>
.chat-form {
    background: rgba(0, 106, 78, 0.36);
    padding: 20px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 28vh
}
</style>