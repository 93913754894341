<template>
    <div class="container">
        <div class="chat-log" >
            <ChatMessage v-for="message in Messages" 
            :Message="message" 
            :key="message.id" />
        </div>
    </div>
</template>

<script>
import ChatMessage from './ChatMessage.vue'
export default {
    components: { ChatMessage },
    watch: {
        Messages(){
            this.$nextTick(() => this.ScrollToLastMessage())
        }
    },      
    computed: {
        Messages() {
            return this.$store.state.Chat.Messages.filter(m => m.message.indexOf("Traga mais detalhes sobre cada ponto comentado") == -1);
        }
    },
    methods: {
        ScrollToLastMessage() {
            var objDiv = document.getElementsByClassName("chat-log__item");
            var lastId = this.$store.state.Chat.LastId;

            if (lastId > 1) {
                var lastMsg = objDiv[lastId-1];
                lastMsg.scrollIntoView()
            }
        }
    }
}
</script>

<style scoped>
.chat-log {
	padding: 40px 0 114px;
	height: auto;
	overflow: auto;
}
</style>